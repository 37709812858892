import { useEffect } from "react";
import WOW from "wowjs";
import StandardMenuHeader from "./themes/StandardMenu/Header";
import ScrollToTop from "./ScrollToTop";
import Footer from './Footer';

function Wrapper({ children }) {
    const handleNavClick = (section) => {
    document.getElementById(section).scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);
    return (
        <>
            <div style={{ position: "relative" }}>
                <div id="main-wrapper">
                <StandardMenuHeader />
                <div id="content" role="main">
                    {children}
                </div>
                <Footer handleNavClick={handleNavClick}></Footer>
                </div>
                <ScrollToTop />
            </div>
        </>
    )
}

export default Wrapper