import React from "react";
import { useNavigate } from "react-router";
import { gql, useQuery } from "urql";

const BlogsQuery = gql`
  query MyBlogQuery {
    blogs(orderBy: publishedAt_DESC) {
      id
      content {
        markdown
      }
      featuredImage {
        fileName
        url
        height
        width
      }
      slug
      createdAt
      title
    }
  }
`;

function useLoadBlogs() {
  const [result] = useQuery({ query: BlogsQuery });
  const { data, fetching, error } = result;

  return { data, fetching, error };
}

const Portfolio = () => {
  const navigate = useNavigate();
  const { data, fetching, error } = useLoadBlogs();

  if (fetching) return <p>Fetching products</p>;
  if (error) return <p>Oh no... {error.message}</p>;

  const navigateToBlog = (slug) => {
    navigate(slug);
  };

  return (
    <>
      <section id="blog" className={"section bg-light"}>
        <div className={"container"}>
          {/* Heading */}
          <p className="text-center mb-2 wow fadeInUp">
            <span className="bg-primary text-dark px-2">Blog</span>
          </p>
          <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
            Seeking Mental Clarity Through The Truth Of God's Word
          </h2>
          <div className="portfolio wow fadeInUp">
            <div className="row portfolio-filter filter-container g-4">
              {data.blogs.map((project, index) => (
                <div className={"col-sm-6 col-lg-4 filter-item"} key={index}>
                  <div className="portfolio-box">
                    <div className="portfolio-img">
                      <img
                        className="img-fluid d-block portfolio-image"
                        src={project.featuredImage.url}
                        alt=""
                      />
                      <div
                        className="portfolio-overlay"
                        onClick={() => navigateToBlog(project.slug)}
                      >
                        <button className="popup-ajax stretched-link border-0 p-0 ">
                          {" "}
                        </button>
                        <div className="portfolio-overlay-details">
                          <h5 className="text-white text-5">
                            {project?.title}
                          </h5>
                          <span className="text-light">Blog</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Portfolio;
