import React from "react";

import MailchimpSubscribe from "react-mailchimp-subscribe";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "./Tooltip";

const ContactSocials = () => (
  <ul className="social-icons social-icons-lg justify-content-center justify-content-lg-start mt-5">
    <li className="social-icons-twitter">
      <Tooltip text="Twitter" placement="top">
        <a
          href="https://twitter.com/AshPowellLISW/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-twitter" />
        </a>
      </Tooltip>
    </li>
    <li className="social-icons-instagram">
      <Tooltip text="Instagram" placement="top">
        <a
          href="http://www.instagram.com/ashleyleepowell"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-instagram" />
        </a>
      </Tooltip>
    </li>
  </ul>
);

const url =
  "https://claritytruth.us21.list-manage.com/subscribe/post?u=0d740699f02929488f8548035&amp;id=9083d74339&amp;f_id=00678ee1f0";

const SubscribeForm = ({ status, message, onValidated }) => {
  let firstName, lastName, email;

  const submit = (e) => {
    e.preventDefault();
    email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
        FNAME: firstName.value,
        LNAME: lastName.value,
      });
  };

  return (
    <form id="contact-form" className="form-border">
      {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
      {status === "error" && (
        <div
          style={{ color: "red" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div style={{ color: "green" }}>Subscribed !</div>
      )}
      <input
        style={{ fontSize: "2em", padding: 5 }}
        ref={(node) => (firstName = node)}
        type="text"
        className="form-control py-1 my-4"
        placeholder="First Name"
      />
      <input
        style={{ fontSize: "2em", padding: 5 }}
        ref={(node) => (lastName = node)}
        type="text"
        className="form-control py-1 my-4"
        placeholder="Last Name"
      />
      <input
        style={{ fontSize: "2em", padding: 5 }}
        ref={(node) => (email = node)}
        type="email"
        className="form-control py-1 my-4"
        placeholder="Email"
      />
      <div className="col-12 text-center text-lg-start">
        <button
          id="submit-btn"
          className="btn btn-dark rounded-0"
          onClick={submit}
        >
          Subscribe
        </button>
      </div>
    </form>
  );
};

const Contact = () => {
  return (
    <section className="section bg-primary">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 text-center text-lg-start wow fadeInUp">
            <h2 className="text-10 fw-600 mb-5">Keep Up To Date</h2>
            <p className="text-5 mb-5">
              Subscribe to get the latest Clarity Truth updates.
            </p>
            <h3 className="text-5 fw-600">Don't Forget To Follow Me:</h3>
            <ContactSocials />
          </div>
          <div
            className="col-lg-6 ms-auto mt-5 mt-lg-0 wow fadeInUp"
            data-wow-delay="0.3s"
          >
            <h2 className="text-10 fw-600 text-center text-lg-start mb-5">
              Subscribe Here
            </h2>
            <MailchimpSubscribe
              url={url}
              render={({ subscribe, status, message }) => (
                <SubscribeForm
                  status={status}
                  message={message}
                  onValidated={(formData) => subscribe(formData)}
                />
              )}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
