import React from "react";
const AboutUs = () => {
  return (
    <section id="about" className="section">
      <div className="container">
        {/* Heading */}
        {/* <p className="text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">About Me</span>
        </p> */}
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          About Me
        </h2>
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-8 text-center text-lg-start wow fadeInUp">
            <h2 className="text-8 fw-400 mb-3">
              Hey, I'm{" "}
              <span className="fw-700 border-bottom border-3 border-primary">
                Ashley Powell
              </span>
            </h2>
            <p className="text-5">
              I am a Licensed Independent Social Worker and Certified
              Clinical Trauma Professional. I entered the social work field in
              2011 and have worked in a variety of settings. I’ve been working
              clinically in private practice since 2019. Therapy is a unique
              process for everyone. I consider it an honor to assist my clients
              in their journey towards emotional healing.
              <br />
              <br />
              Ever since I have been old enough to place pen to paper to create
              words, I have kept a journal. I have been writing my entire life.
              I’ve always found that my thoughts make more sense to me on paper.
              Creating a blog was a natural expression of this in my life. My
              hope is that this blog will seed you with encouragement and
              healthy tools for the (at times) treacherous journey we call life.
              <br />
              <br />
              This blog is a combination of professional mental health
              viewpoints with Christian perspectives. This blog reflects my
              professional experience and my heart that yearns for Christ. This
              blog is less about “what I know” and more about what I have
              learned. Like many of you, I have painful stories in my past. God
              is the author of my healing story and I believe He wants to be
              yours as well. So, I will offer this perspective in my blog posts.
              <br />
              <br />
              Emotional health and mental strength take courage. Let’s dive in
              and be brave together!
            </p>
          </div>
          <div
            className="col-lg-4 mt-4 mt-lg-0 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div className="featured-box style-4">
              <img
                className="img-fluid rounded-pill d-block"
                src="images/ashley-portrait.png"
                title="I'm Ashley"
                alt="Ashely Powell"
              />
            </div>
          </div>
        </div>
        <hr />
        <div className="row gy-3 mt-4">
          <div className="col-6 col-lg-3 wow fadeInUp">
            <p className="text-muted fw-500 mb-0">Education:</p>
            <ul className="text-3 text-dark fw-600 mb-0">
              <li>BA in Social Work from Wright State University</li>
              <li>
                Masters in Social Work from the University of South Dakota
              </li>
            </ul>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp">
            <p className="text-muted fw-500 mb-0">Clinical Focus:</p>
            <ul className="text-3 text-dark fw-600 mb-0">
              <li>Trauma</li>
              <li>Depression</li>
              <li>Anxiety Disorders</li>
              <li>Family Therapy</li>
            </ul>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp">
            <p className="text-muted fw-500 mb-0">Training and Credentials:</p>
            <ul className="text-3 text-dark fw-600 mb-0">
              <li>Ohio Licensed Independent Social Worker (LISW)</li>
              <li>Certified Clinical Trauma Professional (CCTP)</li>
              <li>Trained in EMDR Therapy </li>
            </ul>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.2s">
            <p className="text-muted fw-500 mb-0">Contact:</p>
            <p className="text-3 fw-600 mb-0">
              Ashley Powell <br />
              <a className="link-dark" href="mailto:ashley@claritytruth.com">
                ashley@claritytruth.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
