import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { scrollDuration } from "../../../config/commonConfig";
import { BASEURL } from "../../..";
import Socials from "../../Socials";

const StandardMenuHeader = () => {
  const [isNavModalClose, setIsNavModalClose] = useState(true);
  const [isHome, setIsHome] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;

    if (pathname === "/") {
      setIsHome(true);
    } else {
      setIsHome(false);
    }
  }, [location]);

  return (
    <header id="header" className="sticky-top">
      {/* Navbar */}
      <nav className="primary-menu navbar navbar-expand-lg text-uppercase navbar-line-under-text fw-600">
        <div className="container position-relative">
          <div className="col-auto col-lg-2 d-inline-flex ps-lg-0">
            {/* Logo */}
            <RouterLink
              className="logo"
              title="Clarity"
              style={{ cursor: "pointer" }}
              offset={-72}
              to={"/"}
            >
              <img src={BASEURL + "images/logo.png"} alt="Clarity Truth" />
            </RouterLink>
            {/* Logo End */}
          </div>
          <div className="col col-lg-8 navbar-accordion px-0">
            <button
              className={
                "navbar-toggler ms-auto collapsed " +
                (isNavModalClose ? "" : "show")
              }
              type="button"
              onClick={() => setIsNavModalClose(!isNavModalClose)}
            >
              <span />
              <span />
              <span />
            </button>
            <div
              id="header-nav"
              className={
                "collapse navbar-collapse justify-content-center " +
                (isNavModalClose ? "" : "show")
              }
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  {isHome ? (
                    <Link
                      className="nav-link"
                      smooth="easeInOutQuint"
                      duration={scrollDuration}
                      style={{ cursor: "pointer" }}
                      activeclass="active"
                      spy={true}
                      to="home"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsNavModalClose(true);
                      }}
                    >
                      Home
                    </Link>
                  ) : (
                    <RouterLink
                      className="nav-link "
                      style={{ cursor: "pointer" }}
                      activeclass="active"
                      spy={true}
                      to={"/"}
                      offset={-71}
                    >
                      Home
                    </RouterLink>
                  )}
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link "
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    activeclass="active"
                    spy={true}
                    to="about"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link "
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    activeclass="active"
                    spy={true}
                    to="blog"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    Blog
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link "
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    activeclass="active"
                    spy={true}
                    to="contact"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    Contact
                  </Link>
                </li>
                <li className="nav-item">
                  <a
                    className="btn btn-primary nav-link"
                    target="_blank"
                    rel="noreferrer"
                    href="https://headway.co/providers/ashley-powell?utm_source=pem&utm_medium=direct_link&utm_campaign=52632"
                  >
                    Book{" "}
                    <span className="px-1">
                      <i className="fa fa-calendar" />
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-auto col-lg-2 d-flex justify-content-end ps-0">
            <Socials />
          </div>
        </div>
      </nav>
      {/* Navbar End */}
    </header>
  );
};

export default StandardMenuHeader;
