import { Tooltip } from "./Tooltip";

function Socials() {
    return (
        <ul className="social-icons">
              <li className="social-icons-twitter">
                <Tooltip text="Twitter" placement="bottom">
                  <a
                    href="http://www.twitter.com/AshPowellLISW"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-twitter" />
                  </a>
                </Tooltip>
              </li>
              <li className="social-icons-instagram">
                <Tooltip text="Instagram" placement="bottom">
                  <a
                    href="http://www.instagram.com/ashleyleepowell"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-instagram" />
                  </a>
                </Tooltip>
              </li>
            </ul>
    )
}

export default Socials