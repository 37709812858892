import React from "react";
import { Parallax } from "react-parallax";
import { scrollDuration } from "../config/commonConfig";

const CallToAction = () => {
  return (
    <section id="contact">
      <div className="hero-wrap">
        <div className="hero-mask opacity-8 bg-dark" />
        <Parallax
          bgImage="images/intro-bg-2.jpg"
          bgImageAlt="Intro"
          strength={200}
        >
          <div className="hero-content section">
            <div className="container text-center py-5 wow fadeInUp">
              <h2 className="text-10 fw-600 text-white mb-5">
                Book A Session With Ashley
              </h2>
              <a
                className="btn btn-primary rounded-0 smooth-scroll wow rubberBand"
                target="_blank"
                data-wow-delay="1s"
                smooth="easeInOutQuint"
                duration={scrollDuration}
                rel="noreferrer"
                href="https://headway.co/providers/ashley-powell?utm_source=pem&utm_medium=direct_link&utm_campaign=52632"
              >
                Book A Session{" "}
                <span className="px-1">
                  <i className="fa fa-calendar" />
                </span>
              </a>
            </div>
          </div>
        </Parallax>
      </div>
    </section>
  );
};

export default CallToAction;
