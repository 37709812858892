import { useLocation } from 'react-router-dom';
import { gql, useQuery } from 'urql';
import "./Blog.scss";
import Wrapper from "./components/Wrapper";

const BlogQuery = gql`
query Blogs($slug: String!) {
        blog(where: { slug: $slug}) {
            id
            content {
            html
            }
            title
            createdAt
            featuredImage {
                url
            }
        }
    }
`

function useLoadBlog (slug) {
   const stripSlug = slug.slice(1, slug.length)
   const [result] = useQuery({ query: BlogQuery, variables: { slug: stripSlug }})
   const { data, fetching, error } = result;
   return { data, fetching, error }
}

function Blog() {
  const location = useLocation();
  const { pathname: slug } = location
  const { data, fetching, error } = useLoadBlog(slug)
  
  if (fetching) return <p>Fetching products</p>;
  if (error) return <p>Oh no... {error.message}</p>;

  const convertToDate = (date) => {
    date = new Date(date)
    const month = date.toLocaleString('default', { month: 'long' })

    return `${month} ${date.getDate()},  ${date.getFullYear()}`
  }

  return (
        <Wrapper>
             <div className="container">
                <p className="text-center mb-2 wow fadeInUp">
                <span className="bg-primary text-dark px-2">Blog</span>
                </p>
                <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
                    {data.blog.title}
                </h2>
                <img src={data.blog.featuredImage.url} className="blog-image" alt={data.blog.title} />
                <p className="text-4" dangerouslySetInnerHTML={{ __html: data.blog.content.html }}>
                </p>

                <p className="text-2 published"><strong>Published: </strong><em>{convertToDate(data.blog.createdAt)}</em></p>
            </div>
    </Wrapper>
    )
}

export default Blog
