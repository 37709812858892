import "./App.scss";
import AboutUs from "./components/About";
import CallToAction from "./components/CallToAction";
import Contact from "./components/Contact";
import Portfolio from "./components/Portfolio";
import StandardMenuImgBgIntro from "./components/themes/StandardMenu/IntroImageBg";
import Wrapper from "./components/Wrapper";


function App() {
  return (
    <Wrapper>
      <StandardMenuImgBgIntro />
      <AboutUs />
      <Portfolio />
      <CallToAction />
      <Contact />
    </Wrapper>
  );
}

export default App;
