import React, { useState } from "react";
import { Tooltip } from "./Tooltip";


function useScroll() {
    const [scrollTopVisible, setScrollTopVisible] = useState(false);
  
    const checkScrollTop = () => {
      let scrollTopBtn = document.getElementById("back-to-top");
  
      if (scrollTopBtn) {
        if (
          document.body.scrollTop > 400 ||
          document.documentElement.scrollTop > 400
        ) {
          setScrollTopVisible(true);
        } else {
          setScrollTopVisible(false);
        }
      }
    };
  
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", checkScrollTop);
    }
  
    return { scrollTopVisible }
  }

function ScrollToTop() {
    const { scrollTopVisible } = useScroll();

    return (
      <Tooltip text="Back to Top" placement="left">
      <span
        id="back-to-top"
        className="rounded-circle"
        style={{ display: scrollTopVisible ? "inline" : "none" }}
        onClick={() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      >
        <i className="fas fa-arrow-up"></i>
      </span>
    </Tooltip>
    )
}

export default ScrollToTop;